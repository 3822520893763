export const Messages = {
    uneditableTitle: 'Message Detail',
    uneditableMessageLines: ['This message cannot be edited.', 'There are no remaining messages scheduled to be sent.'],
    confirmDeleteTitle: 'Confirm Delete!',
	confirmDeleteMessageLines: ['You are about to delete this message', 'This cannot be undone! Please confirm'],
	
	reportGenerationMessageTitle: 'Report Generation Status',
	reportGenerationSuccessMessageLines: ['Report was successfully generated!', 'Open the Tableau Dashboard with the link below to view the results'],
	reportGenerationFailureMessageLines: ['An error was encountered while attempting to generate the report!', 'Please check your inputs and try again.'],
}

export const Links = {
	// CrewNeutralTableauReportLink: 'https://tableau.aa.com/t/PSA/views/CrewNeutral/CrewNeutral?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:refresh=yes',
	CrewNeutralTableauReportLink: 'https://tableau.aa.com/t/PSA/views/CrewNeutralReport/CrewNeutral?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:refresh=yes',
};

export const Response = {
	SUCCESS: 'SUCCESS',
	FAILURE: 'FAILURE',
	INVALID: 'INVALID',
}

export const Format = {
	DateIdFormat: 'yyyyMMDD',	
}

export const Routes = {
	PopulateCrewNeutralReport: 'PopulateCrewNeutralReport',
	ScheduleCustomNotification: 'ScheduleCustomNotification',
	RetrieveCustomNotifications: 'RetrieveCustomNotifications',
	UpdateNotification: 'UpdateNotification',
	DeleteNotification: 'DeleteNotification',
	GetRegisteredCrewFromCosmos: 'GetRegisteredCrewFromCosmos',
};

export const LocalDevRoutes = {
	PopulateCrewNeutralReport: 'http://localhost:7071/api/V1/PopulateCrewNeutralReport',
	ScheduleCustomNotification: 'http://localhost:7071/api/V1/ScheduleCustomNotification',
	RetrieveCustomNotifications: 'http://localhost:7071/api/V1/RetrieveCustomNotifications',
	UpdateNotification: 'http://localhost:7071/api/V1/UpdateNotification',
	DeleteNotification: 'http://localhost:7071/api/V1/DeleteNotification',
	GetRegisteredCrewFromCosmos: 'http://localhost:7071/api/V1/GetRegisteredCrewFromCosmos',
};