import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import CrewSelectionReview from '../../custom/crewSelection/CrewSelectionReview';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		'& .MuiTextField-root': {},
	},
	listItem: {
		padding: theme.spacing(1, 0),
	},
	total: {
		fontWeight: 700,
	},
	title: {
		marginTop: theme.spacing(2),
	},
}));

const Review = (): ReactElement => {
	const classes = useStyles();
	const {
		uiStore: { wizardStore },
	} = useStore();
	const { title, content, isGroup, repeat, expires, selectedSendDateTime, lastSent, selectedExpirationDate, getSelectedRecipientGroups, editError } = wizardStore;
	const recipientGroups = getSelectedRecipientGroups();
	return (
		<React.Fragment>
			<Grid className={classes.root} container spacing={2}>
				<Grid item xs={12}>
					<Grid container alignItems='flex-start' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							<Typography>Group Message:</Typography>
						</Grid>
						<Grid item container xs justify='flex-start'>
							<Typography>{isGroup ? 'Yes' : 'No'}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container alignItems='center' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							<Typography>{isGroup ? 'Recipient Groups' : 'Recipient(s)'}:</Typography>
						</Grid>
						<Grid item container xs={7} justify='flex-start'>
							{isGroup ? (
								recipientGroups.map((rg, idx) => (
									<React.Fragment key={idx}>
										<Grid item container xs={1}>
											<Typography>{rg}</Typography>
										</Grid>
									</React.Fragment>
								))
							) : (
								<CrewSelectionReview />
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container alignItems='center' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							<Typography>Title:</Typography>
						</Grid>
						<Grid item container xs={7} justify='flex-start'>
							<TextField disabled id='titleReview' value={title} fullWidth variant='outlined' size='small' multiline rows={1} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container alignItems='flex-start' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							<Typography>Content:</Typography>
						</Grid>
						<Grid item container xs={7} justify='flex-start'>
							<TextField disabled id='contentReview' value={content} fullWidth variant='outlined' size='small' multiline rows={3} />
						</Grid>
					</Grid>
				</Grid>
				{/* DO NOT REMOVE.  THIS WILL BE COMING BACK IN A FUTURE RELEASE 
				<Grid item xs={12}>
					<Grid container alignItems='flex-start' spacing={1}>
						<Grid item container xs={5} justify='flex-end'>
							<Typography>Response Requested:</Typography>
						</Grid>
						<Grid item container xs justify='flex-start'>
							<Typography>{responseRequested ? 'Yes' : 'No'}</Typography>
						</Grid>
					</Grid>
				</Grid> */}
				<Grid item xs={12}>
					<Grid container alignItems='flex-start' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							{editError ? <Typography>Last Sent On:</Typography> : <Typography>Send Message On:</Typography>}
						</Grid>
						<Grid item container xs justify='flex-start'>
							{editError ? <Typography>{moment(lastSent).format('MMMM Do YYYY [at] HH:mm')}</Typography> :
								<Typography>{moment(selectedSendDateTime).format('MMMM Do YYYY [at] HH:mm')}</Typography> }
						</Grid>
					</Grid>
				</Grid>
				{!editError ? (<Grid item xs={12}>
					<Grid container alignItems='flex-start' spacing={1}>
						<Grid item container xs={4} justify='flex-end'>
							<Typography>Frequency:</Typography>
						</Grid>
						<Grid item container xs justify='flex-start'>
							<Typography>{repeat ? 'Daily' : 'Once'}</Typography>
						</Grid>
					</Grid>
				</Grid>) : null}
				{repeat ? (
					<Grid item xs={12}>
						<Grid container alignItems='flex-start' spacing={1}>
							<Grid item container xs={4} justify='flex-end'>
								{editError ? <Typography>Sent Daily Thru:</Typography> : <Typography>Send Daily Until:</Typography>}
							</Grid>
							<Grid item container xs justify='flex-start'>
								<Typography>{expires ? moment(selectedExpirationDate).format('MMMM Do YYYY') : 'Cancelled'}</Typography>
							</Grid>
							<Grid item container xs={5} justify='flex-start'>
								{expires && !editError ? (<Typography variant='caption' color='secondary'>
									*Message will be sent on this day, but not after.
								</Typography>) : null}
							</Grid>
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</React.Fragment>
	);
};

export default observer(Review);
