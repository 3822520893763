import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import {RootStore} from '../RootStore'

export default class ReportStore {
	rootStore: RootStore;
	CNReportStartDate: Moment | null = moment();
	CNReportEndDate: Moment | null = moment();

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}

	setCNReportStartDate = (selectedDate: Moment | null) => {
		selectedDate ? (this.CNReportStartDate = selectedDate) : moment();
	};

	setCNReportEndDate = (selectedDate: Moment | null) => {
		selectedDate ? (this.CNReportEndDate = selectedDate) : moment();
	};

	resetDates = () => {
		this.CNReportStartDate = moment();
		this.CNReportEndDate = moment();
	};
}
