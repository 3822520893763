import { Button, createStyles, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import CustomSelect from './CustomSelect';
import StyledDateTimePicker from '../datePicker/StyledDateTimePicker';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { useAuthenticatedAxios } from '../../../auth/AuthenticatedAxios'
import { Moment } from 'moment'
import { validateStartEndDates } from '../../../utils/DateUtils'

const useStyles = makeStyles(() =>
	createStyles({
		filter: {
			display: 'flex',
			padding: 20,
		},
		textSmall: {
			width: '120px',
		},
		textLarge: {
			width: '300px',
		},
		button: {
			background: 'linear-gradient(135deg, rgba(0,120,181,1) 0%, rgba(0,120,200,1) 20%, rgba(0,100,190,1) 50%, rgba(0,120,200,1) 80%, rgba(0,120,181,1) 100%)',
			color: 'white',
		},
	})
);

const FilterPanel = () => {
	const classes = useStyles();
	const boolFilterOptions = ['ALL', 'YES', 'NO'];
	const {
		uiStore: {
			filterStore,
		},
	} = useStore();
	const { startDate, endDate, crewId, empName, position, isRecurring } = filterStore;
	const { setStartDate, setEndDate, setCrewId, setEmpName, setPosition, setIsRecurring } = filterStore;
	const { client } = useAuthenticatedAxios();

	const resetFilters = () =>
	{
		filterStore.resetFilters();
		filterStore.rootStore.dataStore.crewNotificationStore.loadNotificationsFromCosmos(client);
	};

	const updateStartDate = (selectedDate: Moment | null) => {
		validateStartEndDates(selectedDate, endDate, setStartDate, setEndDate);
		filterStore.rootStore.dataStore.crewNotificationStore.loadNotificationsFromCosmos(client);
	};
	const updateEndDate = (selectedDate: Moment | null) => {
		validateStartEndDates(startDate, selectedDate, setStartDate, setEndDate, false);
		filterStore.rootStore.dataStore.crewNotificationStore.loadNotificationsFromCosmos(client);
	};

	return (
		<div>
			<Paper elevation={3} className={classes.filter}>
				<Grid container spacing={2} justify='flex-start' alignItems='center'>
					<Grid item>
						<StyledDateTimePicker changeHandler={(date) => updateStartDate(date)} selectedDateTime={startDate} label='Start' />
					</Grid>
					<Grid item>
						<StyledDateTimePicker changeHandler={(date) => updateEndDate(date)} selectedDateTime={endDate} label='End' />
					</Grid>
					<Grid item>
						<TextField onChange={(event) => setCrewId(event.target.value)} className={classes.textSmall} variant='outlined' size='small' label='Employee #' value={crewId} />
					</Grid>
					<Grid item>
						<TextField
							onChange={(event) => setEmpName(event.target.value)}
							className={classes.textLarge}
							variant='outlined'
							size='small'
							label='Employee Name'
							value={empName}
						/>
					</Grid>
					<Grid item>
						<TextField
							onChange={(event) => setPosition(event.target.value)}
							className={classes.textSmall}
							variant='outlined'
							size='small'
							label='Position'
							value={position}
						/>
					</Grid>
					<Grid item>
						<CustomSelect value={isRecurring} label='Recurring' onChangeHandler={(e) => setIsRecurring(e.target.value as string)} options={boolFilterOptions} />
					</Grid>
					<Grid item>
						<Button onClick={() => resetFilters()} color='secondary' variant='outlined'>
							Clear
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default observer(FilterPanel);
