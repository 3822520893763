import { makeAutoObservable} from 'mobx';

class RegisteredCrew {
	crewId: string;
	aaId: string;
	position: string;
	firstName: string;
	lastName: string;
	selected: boolean;

	constructor(crewId: string, aaId: string, position: string, firstName: string, lastName: string) {
		makeAutoObservable(this);
		this.crewId = crewId;
		this.aaId = aaId;
		this.position = position;
		this.firstName = firstName;
		this.lastName = lastName;
		this.selected = false;
	}

	get shortName() {
		return `${this.firstName[0]}. ${this.lastName}`;
	}
	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
	get chipName() {
		return `${this.shortName} ${this.crewId}`;
	}
	get optionDisplayName() {
		return `${this.fullName} ${this.crewId} ${this.position}`;
	}
}

export { RegisteredCrew };
