import { AutocompleteChangeReason } from '@material-ui/lab';
import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import React from 'react';
import { RegisteredCrew } from '../../models/RegisteredCrew';
import { Position, WizardStep } from '../../models/CommonTypes';
import { RootStore } from '../RootStore';
import { nextQuarterHour } from '../../utils/DateUtils';

export default class WizardStore {
	rootStore: RootStore;
	createWizardIsVisible = false;
	editWizardIsVisible = false;
	// confirmContinueCreateIsVisible = false;
	activeStepNumber = 1;
	currentStep: WizardStep = 'OPTIONS';
	isGroup = false;
	isCASelected = false;
	isFOSelected = false;
	isFASelected = false;
	selectedSendDateTime: Moment = nextQuarterHour();
	nextOccurrence: Moment | null = null;
	lastSent: Moment | null = null;
	repeat = false;
	expires = true;
	selectedExpirationDate: Moment = nextQuarterHour();
	registeredCrew: RegisteredCrew[] = [];
	selectedRecipients: RegisteredCrew[] = [];
	title = '';
	content = '';
	responseRequested = false;
	missingContentErrorVisible = false;
	scheduleNotificationError = false;
	deleteConfirmationVisible = false;
	statusVisible = false;
	deleteError = false;
	editError = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}
	getSelectedRecipientGroups = (): Position[] => {
		let selectedRecipientGroups: Position[] = [];
		if (this.isCASelected) selectedRecipientGroups.push('CA');
		if (this.isFOSelected) selectedRecipientGroups.push('FO');
		if (this.isFASelected) selectedRecipientGroups.push('FA');

		return selectedRecipientGroups;
	};
	setCreateWizardIsVisible = (isVisible: boolean) => {
		this.createWizardIsVisible = isVisible;
	};
	setEditWizardIsVisible = (isVisible: boolean) => {
		this.editWizardIsVisible = isVisible;
	};
	// setConfirmContinueCreateIsVisible = (isVisible: boolean) => {
	// 	this.confirmContinueCreateIsVisible = isVisible;
	// };
	setIsGroup = (isGroup: boolean) => {
		this.isGroup = isGroup;
		if (!this.isGroup) {
			this.isCASelected = false;
			this.isFOSelected = false;
			this.isFASelected = false;
		}
	};
	setIsCASelected = (isSelected: boolean) => (this.isCASelected = isSelected);
	setIsFOSelected = (isSelected: boolean) => (this.isFOSelected = isSelected);
	setIsFASelected = (isSelected: boolean) => (this.isFASelected = isSelected);
	setRepeat = (repeat: boolean) => (this.repeat = repeat);
	setExpires = (expires: boolean) => {
		if (expires) {
			var currentSend = this.selectedSendDateTime.clone();
			this.selectedSendDateTime = this.validatedDateTime(currentSend);
			this.selectedExpirationDate = this.validatedDateTime(currentSend);
		}
		if (!expires) {
			//Technically, this is set to never expire, but we need to give it a valid date.
			this.selectedExpirationDate = this.validatedDateTime().add(5, 'years');
		}
		this.expires = expires;
	};
	setTitle = (title: string) => (this.title = title);
	setContent = (content: string) => (this.content = content);
	setResponseRequested = (respReq: boolean) => (this.responseRequested = respReq);
	setActiveStepNumber = (step: number) => (this.activeStepNumber = step);
	setScheduleNotificationError = (sendError: boolean) => (this.scheduleNotificationError = sendError);
	setCurrentStep = (toStep: WizardStep) => (this.currentStep = toStep);
	setMissingContentErrorVisible = (isVisible: boolean) => (this.missingContentErrorVisible = isVisible);
	setDeleteConfirmationVisible = (visible: boolean) => (this.deleteConfirmationVisible = visible);
	setStatusVisible = (visible: boolean) => (this.statusVisible = visible);
	setDeleteError = (success: boolean) => (this.deleteError = success);
	setEditError = (editError: boolean) => (this.editError = editError);
	setSelectedSendDateTime = (selectedDate: Moment | null) => {
		if (this.selectedExpirationDate < selectedDate!) {
			this.selectedExpirationDate = this.validatedDateTime(selectedDate!);
		}
		this.selectedSendDateTime = this.validatedDateTime(selectedDate!);
	};
	setNextOccurrence = (selectedDate: Moment | null) => {
		this.nextOccurrence = this.validatedDateTime(selectedDate!);
	};
	setSelectedExpirationDate = (selectedDate: Moment | null) => {
		this.selectedExpirationDate = this.validatedDateTime(selectedDate!);
	};
	setSelectedRecipients = (recipients: RegisteredCrew[]) => {
		this.selectedRecipients = [...recipients];
	};
	clearSelectedRecipients = () => {
		this.selectedRecipients = [];
	};
	validateWizardContent = () => {
		let formIsValid = (this.selectedRecipients.length > 0 || this.isCASelected || this.isFOSelected || this.isFASelected) && this.title.length > 0 && this.content.length > 0;
		return formIsValid;
	};
	updateSelectedRecipients = (e: React.ChangeEvent<{}>, v: RegisteredCrew[], r: AutocompleteChangeReason) => {
		this.selectedRecipients = v;
	};
	validatedDateTime = (date: Moment = moment()): Moment => {
		return nextQuarterHour(date);
	};

	resetWizardContent = () => {
		this.createWizardIsVisible = false;
		this.isGroup = false;
		this.isCASelected = false;
		this.isFOSelected = false;
		this.isFASelected = false;
		this.selectedSendDateTime = this.validatedDateTime();
		this.nextOccurrence = null;
		this.lastSent = null;
		this.repeat = false;
		this.expires = true;
		this.selectedExpirationDate = this.validatedDateTime();
		this.selectedRecipients = [];
		this.title = '';
		this.content = '';
		this.responseRequested = false;
		this.activeStepNumber = 0;
		this.currentStep = 'OPTIONS';
		this.missingContentErrorVisible = false;
		this.scheduleNotificationError = false;
	};

	loadNotificationForEditing = () => {
		const notifToLoad = this.rootStore.dataStore.crewNotificationStore.currentNotification!;
		// var canEdit = moment(notifToLoad.schedule.nextOccurrence).isAfter(moment().utc()) && notifToLoad.schedule.lastSent === null;
		var canEdit = this.rootStore.dataStore.crewNotificationStore.isNotificationEditable(notifToLoad);

		switch (notifToLoad.recipientType) {
			case 'group':
				this.isGroup = true;
				break;
			case 'individual':
				this.isGroup = false;
				break;
		}
		this.repeat = notifToLoad.schedule.recurrence === 'daily';

		this.selectedSendDateTime = moment(notifToLoad.schedule.nextOccurrence);
		this.selectedExpirationDate = moment(notifToLoad.schedule.endDate);
		this.nextOccurrence = moment(notifToLoad.schedule.nextOccurrence);
		this.lastSent = moment(notifToLoad.schedule.lastSent);
		this.title = notifToLoad.pushData.data.title;
		this.content = notifToLoad.pushData.data.body;

		if (this.isGroup) {
			notifToLoad.recipients.forEach((recip) => {
				switch (recip) {
					case 'CA':
						this.isCASelected = true;
						break;
					case 'FO':
						this.isFOSelected = true;
						break;
					case 'FA':
						this.isFASelected = true;
						break;
				}
			});
		} else {
			var recipients: RegisteredCrew[] = [];
			notifToLoad.recipients.forEach((recipient) => {
				var crew = this.rootStore.dataStore.employeeStore.crewRegistry.get(recipient);
				if (crew) {
					recipients.push(crew);
				}
			});
			this.selectedRecipients = recipients;
		}
		if (canEdit) {
		} else {
			this.rootStore.dataStore.crewNotificationStore.currentNotification = undefined;
			// this.resetWizardContent();
			this.editError = true;
		}

		this.editWizardIsVisible = canEdit;
	};
}
