import { Button, createStyles, Grid, makeStyles, Paper } from '@material-ui/core';
import StyledDateTimePicker from '../datePicker/StyledDateTimePicker';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { useAuthenticatedAxios } from '../../../auth/AuthenticatedAxios'
import { Moment } from 'moment'
import { validateStartEndDates } from '../../../utils/DateUtils'

const useStyles = makeStyles(() =>
	createStyles({
		filter: {
			display: 'flex',
			padding: 20,
		},
		textSmall: {
			width: '120px',
		},
		textLarge: {
			width: '300px',
		},
		button: {
			background: 'linear-gradient(135deg, rgba(0,120,181,1) 0%, rgba(0,120,200,1) 20%, rgba(0,100,190,1) 50%, rgba(0,120,200,1) 80%, rgba(0,120,181,1) 100%)',
			color: 'white',
		},
	})
);

const CrewNeutralSelectPanel = () => {
	const classes = useStyles();
	const { client } = useAuthenticatedAxios();
	const {
		uiStore: {
			reportStore,
		},
		dataStore: {
			reportDataStore,
		}
	} = useStore();
	const { CNReportStartDate, CNReportEndDate } = reportStore;
	const { setCNReportStartDate, setCNReportEndDate } = reportStore;
	const { isLoading } = reportDataStore;

	const generateCrewNeutralReport = () =>
	{
		reportDataStore.populateCrewNeutralReport(client);
	}
	const updateStartDate = (selectedDate: Moment | null) => {
		validateStartEndDates(selectedDate, CNReportEndDate, setCNReportStartDate, setCNReportEndDate);
	};
	const updateEndDate = (selectedDate: Moment | null) => {
		validateStartEndDates(CNReportStartDate, selectedDate, setCNReportStartDate, setCNReportEndDate, false);
	};

	return (
		<div>
			<Paper elevation={3} className={classes.filter}>
				<Grid container spacing={2} justify='flex-start' alignItems='center'>
					<Grid item>
						<StyledDateTimePicker changeHandler={(date) => updateStartDate(date)} selectedDateTime={CNReportStartDate} label='Start' />
					</Grid>
					<Grid item>
						<StyledDateTimePicker changeHandler={(date) => updateEndDate(date)} selectedDateTime={CNReportEndDate} label='End' />
					</Grid>
					<Grid item>
						<Button onClick={generateCrewNeutralReport} color='primary' variant='outlined'>
							{isLoading ? 'Generating...' : 'Generate Report'}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={() => reportStore.resetDates()} color='secondary' variant='outlined'>
							Clear
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default observer(CrewNeutralSelectPanel);
