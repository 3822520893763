import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const SignedOutPage = () => {
	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ margin: '10px' }}>
					<Typography variant='h6'>You must log in before you can access the notification dashboard</Typography>
				</Grid>
				<Grid item style={{ margin: '10px' }}></Grid>
			</Grid>
		</React.Fragment>
	);
};
export default SignedOutPage;
