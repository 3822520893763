import React, { ChangeEvent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormLabel, Grid, makeStyles, Radio, RadioGroup, Theme, Typography } from '@material-ui/core';
import StyledDateTimePicker from '../../custom/datePicker/StyledDateTimePicker';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

interface IStyledRadioGroupProps {
	label: string;
	options: string[];
	handler: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
	selected: boolean;
}
const StyledRadioGroup: React.FC<IStyledRadioGroupProps> = ({ label, options, handler, selected }) => {
	return (
		<Grid container alignItems='center' spacing={2}>
			<Grid item container xs={4} justify='flex-end'>
				<FormLabel style={{ color: 'black' }}>{label}:</FormLabel>
			</Grid>
			<Grid item container xs>
				<RadioGroup row onChange={handler}>
					{options.map((item: string, idx: number, opt) => {
						return <FormControlLabel control={<Radio color='primary' />} checked={idx === 0 ? !selected : selected} key={item} value={item} label={item} />;
					})}
				</RadioGroup>
			</Grid>
		</Grid>
	);
};
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
}));

const MessageOptions = (): JSX.Element => {
	const classes = useStyles();
	const {
		uiStore: { wizardStore },
	} = useStore();
	const { isGroup, repeat, expires, selectedSendDateTime, selectedExpirationDate } = wizardStore;
	const {
		setIsGroup,
		setRepeat,
		setExpires,
		setResponseRequested,
		clearSelectedRecipients,
		setSelectedSendDateTime,
		setSelectedExpirationDate,
	} = wizardStore;
	const recipientOptions = ['Individual(s)', 'Group'];
	const frequencyOptions = ['Once', 'Repeat Daily'];
	const expirationOptions = ['Never', 'At a future date'];

	const handleOptionChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
		const target = event.target.value;
		switch (target) {
			case recipientOptions[0]: //Individual(s)
				setResponseRequested(false);
				setIsGroup(false);
				break;
			case recipientOptions[1]: //Group
				clearSelectedRecipients();
				setIsGroup(true);
				break;
			case frequencyOptions[0]: //Once
				setRepeat(false);
				break;
			case frequencyOptions[1]: //Repeat Daily
				setRepeat(true);
				break;
			case expirationOptions[0]: //Never
				setExpires(false);
				break;
			case expirationOptions[1]: //At a future date
				setExpires(true);
				break;
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={12}>
					<StyledRadioGroup label='Recipient Type' options={recipientOptions} handler={handleOptionChange} selected={isGroup} />
				</Grid>

				<Grid item xs={12}>
					<StyledRadioGroup label='Frequency' options={frequencyOptions} handler={handleOptionChange} selected={repeat} />
				</Grid>
				<Grid item xs={12}>
					<Grid container alignItems='center' spacing={2}>
						<Grid item container xs={12} justify='center' spacing={3}>
							<Grid item container xs={4} justify='flex-end'>
								<Typography>Send:</Typography>
							</Grid>
							<Grid item container xs>
								<StyledDateTimePicker
									withTime
									label='On: '
									selectedDateTime={selectedSendDateTime}
									changeHandler={(date) => setSelectedSendDateTime(date)}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} justify='center' spacing={1}>
							<Grid item container xs={4} justify='flex-end'>
								{repeat ? (
									<FormControlLabel
										labelPlacement='end'
										control={<Checkbox checked={expires} color='primary' name='requestResponse' onChange={() => setExpires(!expires)} />}
										label='Expires:'
									/>
								) : null}
							</Grid>
							<Grid item container xs={8} justify='flex-start'>
								{expires && repeat ? (
									<StyledDateTimePicker
										label='On: '
										disable={!expires}
										selectedDateTime={selectedExpirationDate}
										changeHandler={(date) => setSelectedExpirationDate(date)}
									/>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default observer(MessageOptions);
