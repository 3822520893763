import CrewNotificationStore from '../data/CrewNotificationStore';
import EmployeeStore from '../data/EmployeeStore';
import ReportDataStore from '../data/ReportDataStore';
import { RootStore } from '../RootStore';


export default class DataStore
{
	rootStore: RootStore;
	crewNotificationStore: CrewNotificationStore;
	employeeStore: EmployeeStore;
	reportDataStore: ReportDataStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.crewNotificationStore = new CrewNotificationStore(rootStore);
		this.employeeStore = new EmployeeStore(rootStore);
		this.reportDataStore = new ReportDataStore(rootStore);
	}
}
