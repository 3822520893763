import React, { ReactElement } from 'react';

import { AppBar, Toolbar, Typography, Grid, IconButton, Menu, MenuItem, Avatar, Button } from '@material-ui/core';
import logo from '../../assets/images/PSALogo.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAccount, useMsal } from '@azure/msal-react';
import { useStore } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		headerTitle: {
			marginLeft: 10,
			marginTop: 5,
			marginBottom: 0,
			fontSize: 40,
			fontWeight: 'bold',
		},
		logo: {
			marginLeft: 0,
			marginTop: 10,
			marginBottom: 0,
			height: 50,
		},
		avatar: {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main,
		},
		loginButtonContainer: {
			width: '10%',
			margin: 15,
		},
		avatarContainer: {
			width: '10%',
			margin: 0,
		},
	})
);

const Header = (): ReactElement => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const { instance, accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const signedIn = accounts.length > 0;
	instance.setActiveAccount(account);
	const {
		dataStore: {
			employeeStore: { setLoggedInUser },
		},
	} = useStore();

	const getUserInitials = () => {
		const name = account?.name;
		let returnLetters = '';
		if (name) {
			let initials = name.split(', ');
			returnLetters = `${initials[1][0]}${initials[0][0]}`;
		}
		setLoggedInUser(name ? name : '');
		return returnLetters;
	};

	const login = async () => {
		handleMenuClose();
		try {
			await instance.loginRedirect();
		} catch (err) {
			console.log(err);
		}
	};

	const logoutRedirect = async () => {
		sessionStorage.clear();
		await instance.logoutRedirect();
	};
	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}>
			{signedIn ? <MenuItem onClick={logoutRedirect}>Logout</MenuItem> : null}
		</Menu>
	);

	return (
		<React.Fragment>
			<AppBar position='fixed' color='default' className={classes.appBar}>
				<Toolbar disableGutters>
					<Grid container justify='space-between'>
						<Grid container justify='center' style={{ width: 280 }}>
							<a href='/'>
								<img className={classes.logo} src={logo} alt='logo' />
							</a>
						</Grid>
						<Grid container justify='center' style={{ width: '60%' }}>
							<Typography className={classes.headerTitle} color='primary'>
								Crew Suite {process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? '- TEST' : ''}
							</Typography>
						</Grid>
						{signedIn ? (
							<Grid container justify='center' className={classes.avatarContainer}>
								<IconButton edge='end' onClick={handleProfileMenuOpen} color='primary'>
									<Avatar className={classes.avatar}>{getUserInitials()}</Avatar>
								</IconButton>
							</Grid>
						) : (
							<Grid container justify='center' className={classes.loginButtonContainer}>
								<Button variant='contained' size='medium' onClick={login}>
									Login
								</Button>
							</Grid>
						)}
					</Grid>
				</Toolbar>
			</AppBar>
			{renderMenu}
		</React.Fragment>
	);
};

export default observer(Header);
