import React from 'react';
import { Typography } from '@material-ui/core';

const Loading = (): JSX.Element => {
	return (
		<div>
			<Typography variant='h6'>Authentication in progress...</Typography>
		</div>
	);
};

export default Loading;