import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MessageOptions from './MessageOptions';
import MessageContent from './MessageContent';
import Review from './Review';
import { Grid, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/RootStore';
import { useAuthenticatedAxios } from '../../../auth/AuthenticatedAxios';
import { WizardNav, WizardStep } from '../../../models/CommonTypes';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	error: {
		marginBottom: 10,
	},
	paper: {
		margin: 0,
		padding: theme.spacing(3),
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
}));

const steps = ['Message Options', 'Content', 'Review & Send'];

const getWizardStepContent = (currentStep: WizardStep) => {
	switch (currentStep) {
		case 'OPTIONS':
			return <MessageOptions />;
		case 'CONTENT':
			return <MessageContent />;
		case 'REVIEW':
			return <Review />;
		case 'FINAL':
			break;
		default:
			throw new Error('Unknown step');
	}
};

const NotificationWizard = () => {
	const { client } = useAuthenticatedAxios();
	const {
		uiStore: { wizardStore },
		dataStore: {
			crewNotificationStore: { scheduleNotification, loadNotificationsFromCosmos },
		},
	} = useStore();
	const {
		setCreateWizardIsVisible,
		resetWizardContent,
		validateWizardContent,
		setMissingContentErrorVisible,
		setCurrentStep,
		setActiveStepNumber,
		setScheduleNotificationError,
	} = wizardStore;
	const classes = useStyles();
	const { activeStepNumber, currentStep, isGroup, missingContentErrorVisible, scheduleNotificationError } = wizardStore;

	const handleScheduleNotification = () => {
		scheduleNotification(client)
			.then(() => {
				setCurrentStep('FINAL');
			})
			.catch((error) => {
				setScheduleNotificationError(true);
			});
	};	

	const handleWizardNav = (navigate: WizardNav) => {
		switch (currentStep) {
			case 'OPTIONS':
				switch (navigate) {
					case 'FORWARD':
						setCurrentStep('CONTENT');
						setActiveStepNumber(2);
						break;
					default:
						break;
				}
				break;

			case 'CONTENT':
				switch (navigate) {
					case 'FORWARD':
						if (validateWizardContent()) {
							setMissingContentErrorVisible(false);
							setCurrentStep('REVIEW');
							setActiveStepNumber(3);
						} else {
							setMissingContentErrorVisible(true);
						}
						break;
					case 'BACK':
						setCurrentStep('OPTIONS');
						setActiveStepNumber(1);
						setMissingContentErrorVisible(false);
						break;
				}
				break;

			case 'REVIEW':
				switch (navigate) {
					case 'FORWARD':
						handleScheduleNotification();
						break;
					case 'BACK':
						setCurrentStep('CONTENT');
						setActiveStepNumber(2);
						break;
				}
				break;
		}
	};
	const handleClose = () => {
		setCreateWizardIsVisible(false);
		resetWizardContent();
		loadNotificationsFromCosmos(client);
	};
	const handleCloseError = () => {
		setMissingContentErrorVisible(false);
	};

	return (
		<React.Fragment>
			<Paper className={classes.paper} variant='elevation'>
				<Typography component='h1' variant='h4' align='center'>
					Custom Message
				</Typography>
				<Stepper activeStep={activeStepNumber - 1} className={classes.stepper}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{missingContentErrorVisible ? (
					<Alert className={classes.error} onClose={handleCloseError} severity='error'>
						{`Message must have Title, Content and ${isGroup ? 'Group' : 'Recipient(s)'}`}
					</Alert>
				) : null}
				<React.Fragment>
					{currentStep === 'FINAL' ? (
						<React.Fragment>
							<Grid container className={classes.root} spacing={2}>
								<Grid item xs={12}>
									<Grid container justify='center'>
										<Typography variant='h5' gutterBottom>
											{scheduleNotificationError ? 'There was an error while attempting to schedule the message' : 'Message was scheduled'}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container justify='center'>
										<Button variant='text' color='primary' onClick={handleClose} className={classes.button}>
											Close
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</React.Fragment>
					) : (
						<React.Fragment>
							{getWizardStepContent(currentStep)}
							<div className={classes.buttons}>
								{currentStep !== 'OPTIONS' && (
									<Button onClick={() => handleWizardNav('BACK')} className={classes.button}>
										Back
									</Button>
								)}
								<Button variant='text' color='secondary' onClick={handleClose} className={classes.button}>
									Cancel
								</Button>
								<Button variant='contained' onClick={() => handleWizardNav('FORWARD')} className={classes.button}>
									{currentStep === 'REVIEW' ? 'Schedule Notification' : 'Next'}
								</Button>
							</div>
						</React.Fragment>
					)}
				</React.Fragment>
			</Paper>
		</React.Fragment>
	);
};

export default observer(NotificationWizard);
