import { ReactElement } from 'react';
import DateUtils from '@date-io/moment';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Moment } from 'moment';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		datePicker: {
			width: '120px',
		},
		dateTimePicker: {
			width: '160px',
		},
	})
);

type StyledDatePickerProps = {
	label: string;
	withTime?: boolean;
	disable?: boolean;
	selectedDateTime: Moment | null;
	changeHandler: (date: Moment | null) => void;
};

const StyledDateTimePicker = (props: StyledDatePickerProps): ReactElement => {
	const classes = useStyles();
	const { label, withTime, selectedDateTime, changeHandler, disable } = props;
	const displayFormat = withTime ? 'MM/DD/yyyy HH:mm' : 'MM/DD/yyyy';
	const pickerDisplayClass = withTime ? classes.dateTimePicker : classes.datePicker;

	return (
		<MuiPickersUtilsProvider utils={DateUtils}>
			{withTime ? (
				<DateTimePicker
					disablePast
					className={pickerDisplayClass}
					ampm={false}
					minutesStep={15}
					autoOk
					size='small'
					variant='inline'
					inputVariant='outlined'
					format={displayFormat}
					label={label}
					value={selectedDateTime}
					onChange={changeHandler}
					minDateMessage=''
				/>
			) : (
				<DatePicker
					disabled={disable}
					// disablePast
					disableToolbar
					className={pickerDisplayClass}
					autoOk
					size='small'
					variant='inline'
					inputVariant='outlined'
					format={displayFormat}
					label={label}
					value={selectedDateTime}
					onChange={changeHandler}
				/>
			)}
		</MuiPickersUtilsProvider>
	);
};

export default StyledDateTimePicker;
