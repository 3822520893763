import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import { AxiosInstance } from 'axios';
import moment from 'moment';
import { Routes, Response, Format } from '../../utils/CommonConstants';
// import { LocalDevRoutes } from '../../utils/CommonConstants';

export default class ReportDataStore {
	rootStore: RootStore;
	CNReportGenerationMessage: string = '';
	isLoading = false;
	reportGenerationAlertVisible = false;
	reportGenerationWasSuccessful = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}
	setIsLoading = (loading: boolean) => (this.isLoading = loading);
	setReportGenerationAlertVisible = (rgAlertVisible: boolean) => (this.reportGenerationAlertVisible = rgAlertVisible);

	setCNReportGenerationMessage = (message: string) =>
	{
		this.CNReportGenerationMessage = message;
		this.setReportGenerationWasSuccessful(message === Response.SUCCESS);		
	};

	setReportGenerationWasSuccessful = (success: boolean) =>
	{
		this.reportGenerationWasSuccessful = success;
	}

	populateCrewNeutralReport = async (client: AxiosInstance) => {
		this.setIsLoading(true);

		var reportStartDate = moment(this.rootStore.uiStore.reportStore.CNReportStartDate);
		var reportEndDate = moment(this.rootStore.uiStore.reportStore.CNReportEndDate);

		client
			// .post(LocalDevRoutes.PopulateCrewNeutralReport, {}, {
			.post(`/${Routes.PopulateCrewNeutralReport}`, {}, {
				params: {
					startDate: reportStartDate.format(Format.DateIdFormat),
					endDate: reportEndDate.format(Format.DateIdFormat),
				},
			})
			.then((response) => {
				runInAction(() =>
				{
					this.setCNReportGenerationMessage(response.data);
					this.setReportGenerationAlertVisible(true);
				});
			})
			.catch((error) => {
				console.log(`Error encountered while generating Crew Neutral Report: ${error}`);
				this.setCNReportGenerationMessage(error);
			})
			.finally(() => {
				this.setIsLoading(false);
			});
	};
}
