import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Search } from '@material-ui/icons';
import List from '@material-ui/core/List';
import { Dashboard } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItemLink from './ListItemLink';
import { observer } from 'mobx-react-lite';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		offset: {
			...theme.mixins.toolbar,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
	})
);

const SideDrawer: React.FC = () => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Drawer variant='permanent' className={classes.drawer}>
				<div className={classes.offset} />
				<List>
					<ListItemLink to='/messages' primary='Communication Management' icon={<Dashboard />}></ListItemLink>
					<React.Fragment>
						<Divider variant='middle' />
						<ListItemLink to='/crewneutral' primary='Crew Neutral Report' icon={<Search />}></ListItemLink>
						<Divider variant='middle' />
					</React.Fragment>
				</List>
			</Drawer>
		</React.Fragment>
	);
};

export default observer(SideDrawer);
