import ReactDOM from 'react-dom';
import App from './App';
import { LicenseInfo } from '@material-ui/x-grid';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreProvider, createStore } from './stores/RootStore';

const license = process.env.REACT_APP_MUI_LICENSE ? process.env.REACT_APP_MUI_LICENSE : '';
LicenseInfo.setLicenseKey(license);

const rootStore = createStore();

ReactDOM.render(
	<StoreProvider store={rootStore}>
		<Router>
			<App pca={rootStore.msalInstance} />
		</Router>
	</StoreProvider>,
	document.getElementById('root')
);