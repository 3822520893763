import Schedule from './Schedule';
import { RecipientType, ResponseOption } from './CommonTypes';
import { makeAutoObservable } from 'mobx';
import PushMessage from './PushData';

class CrewNotification {
	sender: string;
	recipientType: RecipientType;
	recipients: string[];
	schedule: Schedule;
	pushData: PushMessage;
	responseOption: ResponseOption;
	notificationId: string;
	createdDateId: string;

	constructor(
		sender: string,
		recipientType: RecipientType,
		recipients: string[],
		schedule: Schedule,
		pushData: PushMessage,
		createdDateId: string = '',
		notificationId: string = '',
		responseOption: ResponseOption = 'none'
	) {
		makeAutoObservable(this, { notificationId: false, recipients: false });
		this.sender = sender;
		this.recipientType = recipientType;
		this.recipients = recipients;
		this.schedule = schedule;
		this.pushData = pushData;
		this.createdDateId = createdDateId;
		this.notificationId = notificationId;
		this.responseOption = responseOption;
	}
}
export default CrewNotification;
