import { createMuiTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { grey } from '@material-ui/core/colors';
import * as AmericanFont from './assets/fonts';

const aaBlue = '#0078D2';
// const aaBlueLight = '#4db4fa'
const aaBlueDark = '#00467f';
// const aaGreen = '#00b989';
// const aaYellowGreen = '#d1d532';
// const aaLightOrange = '#faaf00';
// const aaOrange = '#ff7318';
const aaRed = '#C30019';
const aaGrayLight = '#d0dae0';
// const aaGray = '#9da6ab'
// const aaGrayDark = '#36495a';
// const aaBlack = '#131313';
const gradient = 'linear-gradient(135deg, rgba(0,120,181,1) 0%, rgba(0,120,200,1) 20%, rgba(0,100,190,1) 50%, rgba(0,120,200,1) 80%, rgba(0,120,181,1) 100%)';

export default createMuiTheme({
	palette: {
		primary: {
			main: aaBlue,
		},
		secondary: {
			main: aaRed,
		},
		background: {
			paper: grey[100],
			default: grey[200],
		},
		action: {
			disabled: aaGrayLight,
		},
	},
	typography: {
		fontFamily: 'American',
		// h1: {
		// 	fontWeight: 400,
		// },
		// fontWeightLight: 100,
		// fontWeightRegular: 200,
		// fontWeightMedium: 300,
		// fontWeightBold: 500,
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				// "@font-face": {

				// }
				'@font-face': [
					AmericanFont.Bold,
					AmericanFont.Medium,
					AmericanFont.Regular,
					// AmericanFont.Book,
					// AmericanFont.Light,
					// AmericanFont.LightItalic,
					// AmericanFont.BookItalic,
					// AmericanFont.RegularItalic,
					// AmericanFont.MediumItalic,
					// AmericanFont.BoldItalic,
				],
			},
		},
		MuiButton: {
			// X-Grid Toolbar button
			textPrimary: {
				color: aaBlue,
			},
			contained: {
				background: gradient,
				color: 'white',
			},
		},
		MuiDialog: {
			paper: {
				border: 'solid 1px',
				borderColor: aaBlueDark,
				borderRadius: 20,
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: aaBlue,
				height: 0,
			},
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				color: aaBlue,
			},
		},
		MuiPickersDay: {
			day: {
				color: aaBlue,
			},
			daySelected: {
				backgroundColor: aaBlue,
			},
			current: {
				color: aaBlue,
			},
		},
		MuiPickersModal: {
			dialogAction: {
				color: lightBlue['400'],
			},
		},
	},
});
