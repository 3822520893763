import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
	})
);

const GridLayout: React.FC = ({children}) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Grid className={classes.content} container>
				<Grid item xs>
					<div className={classes.toolbar} />
					{children}
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default GridLayout