import React from 'react';
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { createStyles, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite'

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: string;
	to: string;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		active: {
			color: theme.palette.primary.main
		}
	})
);


const ListItemLink: React.FC<ListItemLinkProps> = ({ icon, primary, to }) => {
	const classes = useStyles();
	const renderLink = React.useMemo(
		() => React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => <RouterLink activeClassName={classes.active} to={to} ref={ref} {...itemProps} />),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[to]
	);

	return (
		<li>
			<ListItem button component={renderLink} style={{ marginTop: 15 }}>
				{icon ? <ListItemIcon style={{ marginRight: -20 }}>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
};

export default observer(ListItemLink);
