import GridLayout from './GridLayout';
import { Route, Switch, Redirect } from 'react-router-dom';
import CustomNotificationDashboard from '../../pages/CustomNotificationDashboard';
import Home from '../../pages/Home';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import SignedOutPage from '../../pages/SignedOutPage';
import { observer } from 'mobx-react-lite';
import CrewNeutralReport from '../../pages/CrewNeutralReport'

const MainContent = () => {
	return (
			<GridLayout>
				<UnauthenticatedTemplate>
					<Route exact path='/' component={Home} />
					<Route path='/signedOutPage' component={SignedOutPage} />
				</UnauthenticatedTemplate>
				<AuthenticatedTemplate>
					<Switch>
						<Route path='/' exact>
							<Redirect to='/home' />
						</Route>
						<Route path='/home'>
							<Home/>
						</Route>
						<Route path='/messages' exact>
							<CustomNotificationDashboard/>
						</Route>
						<Route path='/crewneutral' exact>
							<CrewNeutralReport />
						</Route>
					</Switch>
				</AuthenticatedTemplate>
			</GridLayout>
	);
};
export default observer(MainContent);
