import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { apiConfig } from './AuthConfig';

const useAuthenticatedAxios = () => {
	const { instance } = useMsal();
	const account = instance.getAllAccounts()[0];

	const client = axios.create();
	client.defaults.baseURL = apiConfig.notificationManagerUrl;

	if (account) {
		const request = { scopes: apiConfig.resourceScopes, account: account };
		instance
			.acquireTokenSilent(request)
			.then((response) => {
				client.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
			})
			.catch(async (err) => {
				instance.acquireTokenPopup(request).then((response) => {
					// client.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
					client.interceptors.request.use((config) => {
						config.baseURL = apiConfig.notificationManagerUrl;
						// instance.acquireTokenSilent(request).then((response) => {
						config.headers['Authorization'] = `Bearer ${response.accessToken}`;
						// });
						return config;
					});
				});
			})
			.catch((err) => {
				console.log(`Error getting token for axios instance: ${err}`);
			});
	}

	return { client };
};
export { useAuthenticatedAxios };
