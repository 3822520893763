import moment, { Moment } from 'moment';

export const nextQuarterHour = (date: Moment = moment()): Moment => {
	if (date.isBefore(moment())) {
		date = moment();
	}
	var dateClone = date.clone();
	var minutes = dateClone.minute();

	dateClone.startOf('hour');

	if (minutes % 15 === 0) {
		return date;
	}
	if (minutes < 15) {
		return dateClone.minute(15);
	}
	if (minutes < 30) {
		return dateClone.minute(30);
	}
	if (minutes < 45) {
		return dateClone.minute(45);
	}
	return dateClone.add(1, 'hour');
};

export const validateStartEndDates = (startDate: Moment | null, endDate: Moment | null, setStartDateFunc: CallableFunction, setEndDateFunc: CallableFunction, validateStart: boolean = true) =>
{
	setStartDateFunc(startDate)
	setEndDateFunc(endDate)
	if (startDate! > endDate!)
	{
		setStartDateFunc(validateStart ? startDate : endDate)
		setEndDateFunc(validateStart ? startDate : endDate)
	};
}
