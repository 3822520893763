import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import StyledDateTimePicker from '../../custom/datePicker/StyledDateTimePicker';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import CrewGroupSelector from '../../custom/crewSelection/CrewGroupSelector';
import CrewSelector from '../../custom/crewSelection/CrewSelector';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
}));

const EditMessageOptions = (): JSX.Element => {
	const classes = useStyles();
	const {
		uiStore: { wizardStore },
	} = useStore();
	const {
		isGroup,
		repeat,
		expires,
		// selectedSendDateTime,
		nextOccurrence,
		selectedExpirationDate,
		title,
		content,
		setExpires,
		// setSelectedSendDateTime,
		setNextOccurrence,
		setSelectedExpirationDate,
		setTitle,
		setContent,
	} = wizardStore;

	const handleValidateInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		//Limit the line breaks to 5
		if (e.target.value.split('\n').length > 5) {
			setContent(e.target.value.trimEnd());
		} else {
			setContent(e.target.value);
		}
	};

	return (
		<React.Fragment>
			<Grid container className={classes.root} spacing={1}>
				<Grid container className={classes.root} spacing={2} style={{ marginBottom: 20 }}>
					<Grid item xs={12}>
						<Grid container alignItems='center' spacing={2}>
							<Grid item container xs justify='flex-end'>
								<Typography>Next Send:</Typography>
							</Grid>
							<Grid item container xs>
								<StyledDateTimePicker
									withTime
									label='On: '
									selectedDateTime={nextOccurrence}
									changeHandler={(date) => setNextOccurrence(date)}
								/>
							</Grid>
							<Grid item container xs justify='flex-start'>
								{repeat ? (
									<FormControlLabel
										labelPlacement='start'
										control={<Checkbox checked={expires} color='primary' name='requestResponse' onChange={() => setExpires(!expires)} />}
										label='Expire'
									/>
								) : null}
							</Grid>
							<Grid item container xs={4}>
								{expires && repeat ? (
									<StyledDateTimePicker
										label='On: '
										disable={!expires}
										selectedDateTime={selectedExpirationDate}
										changeHandler={(date) => setSelectedExpirationDate(date)}
									/>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{isGroup ? <CrewGroupSelector /> : <CrewSelector />}
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						inputProps={{ maxLength: 30 }}
						id='notificationTitle'
						onChange={(e) => setTitle(e.target.value)}
						value={title}
						label='Title'
						fullWidth
						variant='outlined'
						size='small'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						inputProps={{ maxLength: 150 }}
						id='notificationContent'
						onChange={(e) => handleValidateInput(e)}
						value={content}
						label='Message Content'
						fullWidth
						variant='outlined'
						multiline
						rows={10}
					/>
					<Typography style={{ color: 'gray' }} align='right'>{`${content.length}/150`}</Typography>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default observer(EditMessageOptions);
