import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import {RootStore} from '../RootStore'

export default class FilterStore {
	rootStore: RootStore;
	startDate: Moment | null = moment();
	endDate: Moment | null = moment();
	crewId: string = '';
	empName: string = '';
	position: string = '';
	isRecurring: string = 'ALL';

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}

	setStartDate = (selectedDate: Moment | null) => {
		selectedDate ? (this.startDate = selectedDate) : moment();
	};
	setEndDate = (selectedDate: Moment | null) => {
		selectedDate ? (this.endDate = selectedDate) : moment();
	};
	setCrewId = (crewId: string) => {
		this.crewId = crewId;
	};
	setEmpName = (empName: string) => {
		this.empName = empName;
	};
	setPosition = (pos: string) => {
		this.position = pos;
	};
	setIsRecurring = (isRecurring: string) =>
	{
		this.isRecurring = isRecurring.toString();
	};

	resetFilters = () => {
		this.startDate = moment();
		this.endDate = moment();
		this.crewId = '';
		this.empName = '';
		this.position = '';
		this.isRecurring = 'ALL';
	};
}
