import { PublicClientApplication } from '@azure/msal-browser';
import { createContext, FC, useContext } from 'react';
import { MSAL_CONFIG } from '../auth/AuthConfig';
import DataStore from './data/DataStore';
import UiStore from './ui/UiStore';

class RootStore {
	msalInstance: PublicClientApplication = new PublicClientApplication(MSAL_CONFIG);
	uiStore: UiStore = new UiStore(this);
	dataStore: DataStore = new DataStore(this);
}

const StoreContext = createContext<RootStore>(new RootStore());

const StoreProvider: FC<{ store: RootStore }> = ({ store, children }) => {
	return <StoreContext.Provider value={store}> {children}</StoreContext.Provider>;
};

const createStore = () => new RootStore();
const useStore = () => useContext(StoreContext);

export { RootStore, StoreProvider, useStore, createStore };
