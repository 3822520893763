import { observer } from 'mobx-react-lite';
import { GridToolbarExport, GridToolbarContainer, GridColDef, GridValueFormatterParams, GridRowParams, GridCellParams } from '@material-ui/x-grid';
import { XGrid } from '@material-ui/x-grid';
import { useStore } from '../../../stores/RootStore';
import { GridDensitySelector } from '@material-ui/x-grid';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Container, Paper, Theme } from '@material-ui/core';
import { Moment } from 'moment';
import React from 'react';
// import moment from 'moment';
import AlertDialog from '../../../utils/Alert';
import { runInAction } from 'mobx';
import { Messages } from '../../../utils/CommonConstants';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import Review from '../../notificationWizard/create/Review'

const columns: GridColDef[] = [
	{
		field: 'options',
		headerName: ' ',
		flex: 0.25,
		renderCell: (params: GridCellParams) => (
			<React.Fragment>
				{params.value === 'edit' ? <EditRoundedIcon color='primary' fontSize='small' /> : <NotInterestedRoundedIcon color='secondary' fontSize='small' />}
			</React.Fragment>
		),
	},
	{
		field: 'crewId',
		flex: 1,
		headerName: 'Employee #',
		renderCell: (params: GridCellParams) => <React.Fragment>{params.value === 'Group' ? <GroupRoundedIcon color='primary' fontSize='small' /> : params.value}</React.Fragment>,
	},
	{
		field: 'empName',
		flex: 1,
		headerName: 'Employee',
		renderCell: (params: GridCellParams) => (
			<React.Fragment>{params.value === 'Group' ? <GroupRoundedIcon color='primary' fontSize='small' /> : params.value === 'multi' ? params.value : params.value}</React.Fragment>
		),
	},
	{ field: 'pos', flex: 1, headerName: 'Position' },
	{ field: 'sender', flex: 1, headerName: 'Created By' },
	{ field: 'created', type: 'dateTime', flex: 1, headerName: 'Created On', valueFormatter: (params: GridValueFormatterParams) => (params.value as Moment).format('MM/DD/yyyy [UTC]') },
	{ field: 'recurring', hide: true },
];

const NotificationsTable = () => {
	const {
		uiStore: {
			filterStore: { crewId, empName, position },
			wizardStore: { editError, setEditError, loadNotificationForEditing, resetWizardContent },
		},
		dataStore: {
			crewNotificationStore: { isLoading, notificationRegistry, notificationTableData, setCurrentNotification },
		},
	} = useStore();

	const useToolbarStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				flex: 2,
				marginLeft: '80%',
				marginRight: '1%',
				display: 'flex',
				justifyContent: 'space-around',
			},
		})
	);

	function CustomToolbar() {
		const classes = useToolbarStyles();
		return (
			<GridToolbarContainer className={classes.root}>
				<GridDensitySelector />
				<GridToolbarExport />
			</GridToolbarContainer>
		);
	}

	const handleRowDoubleClick = (params: GridRowParams, event: React.MouseEvent) => {
		runInAction(() => {
			var notif = notificationRegistry.get(params.id.toString());
			if (notif) {
				setCurrentNotification(notif);
				loadNotificationForEditing();
			}
		});
	};

	return (
		<React.Fragment>
			<Paper elevation={3}>
				<Container maxWidth='xl' disableGutters>
					<XGrid
						rows={notificationTableData}
						columns={columns}
						components={{ Toolbar: CustomToolbar }}
						autoHeight
						autoPageSize
						loading={isLoading}
						disableColumnMenu
						onRowDoubleClick={(params, event) => handleRowDoubleClick(params, event)}
						disableSelectionOnClick
						density='compact'
						filterModel={{
							items: [
								// { columnField: 'sent', operatorValue: 'contains', value: test() },
								{ columnField: 'crewId', operatorValue: 'contains', value: crewId },
								{ columnField: 'empName', operatorValue: 'contains', value: empName },
								{ columnField: 'pos', operatorValue: 'contains', value: position },
								// { columnField: 'recurring', operatorValue: 'contains', value: isRecurring === 'ALL' ? '' : isRecurring},
							],
						}}
					/>
					{/* </div> */}
					<AlertDialog
						open={editError}
						title={Messages.uneditableTitle}
						messageLines={Messages.uneditableMessageLines}
						confirmHandler={() => {
							setEditError(false);
							resetWizardContent();
						}}
						confirmLabel='OK'
						additionalContent={<Review />}
					/>
				</Container>
			</Paper>
		</React.Fragment>
	);
};

export default observer(NotificationsTable);
