import React, { ReactElement } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import theme from './Theme'
import { MsalProvider } from '@azure/msal-react'
import MainLayout from './components/layout/MainLayout'
import { CssBaseline } from '@material-ui/core'
import { PublicClientApplication } from '@azure/msal-browser'

export default function App({ pca }: { pca: PublicClientApplication }): ReactElement
{
	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<CssBaseline/>
				<MsalProvider instance={pca}>
					<MainLayout />
				</MsalProvider>
			</ThemeProvider>
		</React.Fragment>
	);
}
