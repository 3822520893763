import React, { ReactElement } from 'react';
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, MenuProps, Select, Theme } from '@material-ui/core';
import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

type Props = {
	value: string;
	label: string;
	onChangeHandler: (event: React.ChangeEvent<{ value: unknown }>) => void;
	options: string[];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(0),
			minWidth: 120,
		},
	})
);

const DropDownPositionProps: Partial<MenuProps> = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	getContentAnchorEl: null,
};
const CustomSelect = (props: Props): ReactElement => {
	const classes = useStyles();
	const { value, label, onChangeHandler, options } = props;
	return (
		<Fragment>
			<FormControl margin='dense' variant='outlined' className={classes.formControl}>
				<InputLabel>{label}</InputLabel>
				<Select MenuProps={DropDownPositionProps} value={value} onChange={onChangeHandler} label={label}>
					{options.map((item: string) => {
						return (
							<MenuItem  key={item} value={item === 'YES' ? 1 : item === 'NO' ? 0 : item}>
								{item}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</Fragment>
	);
};
export default observer(CustomSelect);
