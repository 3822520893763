import { makeAutoObservable, runInAction } from 'mobx';
import CrewNotification from '../../models/CrewNotification';
import { GridRowsProp } from '@material-ui/x-grid';
import { RootStore } from '../RootStore';
import Schedule from '../../models/Schedule';
import PushMessage from '../../models/PushData';
import { AxiosInstance } from 'axios';
import { RecipientType } from '../../models/CommonTypes';
import moment from 'moment';
import { Routes } from '../../utils/CommonConstants';
import { nextQuarterHour } from '../../utils/DateUtils'

export default class CrewNotificationStore {
	rootStore: RootStore;
	notificationRegistry = new Map<string, CrewNotification>();
	notificationTableData: GridRowsProp = [];
	currentNotification: CrewNotification | undefined;
	isLoading = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}
	setCurrentNotification = (notif: CrewNotification) => (this.currentNotification = notif);
	setIsLoading = (loading: boolean) => (this.isLoading = loading);

	loadNotificationsFromCosmos = async (client: AxiosInstance) => {
		let notifsFromCosmos: CrewNotification[] = [];
		this.setIsLoading(true);
		this.notificationRegistry.clear();

		var utcStart = moment(this.rootStore.uiStore.filterStore.startDate).utc();
		var utcEnd = moment(this.rootStore.uiStore.filterStore.endDate).utc();

		client
			.get(`/${Routes.RetrieveCustomNotifications}`, {
				params: {
					startDate: utcStart.format('yyyy-MM-DD'),
					endDate: utcEnd.format('yyyy-MM-DD'),
					// startDate: this.rootStore.uiStore.filterStore.startDate?.utc().format('yyyy-MM-DD'),
					// endDate: this.rootStore.uiStore.filterStore.endDate?.utc().format('yyyy-MM-DD'),
				},
			})
			.then((response) => {
				notifsFromCosmos = [...response.data];
				notifsFromCosmos.forEach((notif) => {
					this.notificationRegistry.set(notif.notificationId, notif);
				});
				runInAction(() => {
					this.notificationTableData = this.createTableData();
				});
			})
			.catch((error) => {
				console.log(`Error encountered while retreiving notifications: ${error}`);
			})
			.finally(() => {
				this.setIsLoading(false);
			});
	};

	updateNotification = async (client: AxiosInstance) =>
	{
		client
			.post(`/${Routes.UpdateNotification}`, this.currentNotification)
			.then((response) => {
				this.rootStore.uiStore.wizardStore.setEditWizardIsVisible(false);
				this.rootStore.uiStore.wizardStore.resetWizardContent();
				this.loadNotificationsFromCosmos(client);
			})
			.catch((err) => {
				console.log(`An error occurred while attempting to update the notification: ${err}`);
			});
	};
	deleteNotification = async (client: AxiosInstance) => {
		client
			.post(`/${Routes.DeleteNotification}`, this.currentNotification)
			.then(() => {
				this.rootStore.uiStore.wizardStore.setDeleteError(false);
				this.rootStore.uiStore.wizardStore.setStatusVisible(true);
				this.loadNotificationsFromCosmos(client);
				console.log('Notification has been deleted');
			})
			.catch((err) => {
				console.log(`An error occurred while attempting to delete the notification: ${err}`);
			});
	};
	scheduleNotification = async (client: AxiosInstance) => {
		let ws = this.rootStore.uiStore.wizardStore;
		let recipients: string[] = [];

		if (ws.isGroup) {
			if (ws.isCASelected) recipients.push('CA');
			if (ws.isFOSelected) recipients.push('FO');
			if (ws.isFASelected) recipients.push('FA');
		} else {
			ws.selectedRecipients.forEach((recip) => {
				recipients.push(recip.crewId);
			});
		}

		// We need to make sure that the selected send time is in the future in case the message creation wizard
		// is left open for an extended period of time before being submitted for scheduling.
		// We also add a 5 minute buffer to allow for the time between timer function runs
		var validatedSendDateTime = ws.selectedSendDateTime > moment().utc().add(5, 'minute') ? ws.selectedSendDateTime : nextQuarterHour(ws.selectedSendDateTime);

		var sender = this.rootStore.dataStore.employeeStore.loggedInUser ?? 'Unknown';
		var recipType: RecipientType = ws.isGroup ? 'group' : 'individual';
		// var schedule: Schedule = new Schedule(ws.selectedSendDateTime, ws.selectedExpirationDate, ws.repeat ? 'daily' : 'once');
		var schedule: Schedule = new Schedule(validatedSendDateTime, ws.selectedExpirationDate, ws.lastSent ?? null, validatedSendDateTime, ws.repeat ? 'daily' : 'once');
		var pushMessage: PushMessage = new PushMessage(ws.title, ws.content);

		var notif = new CrewNotification(sender, recipType, recipients, schedule, pushMessage);

		client
			.post(`/${Routes.ScheduleCustomNotification}`, notif)
			.then(() => {
				// this.sendTestNotification(client);
				console.log('Notification has been scheduled');
			})
			.catch((err) => {
				console.log(`An error occurred while attempting to schedule the notification: ${err}`);
			});
	};

	isNotificationEditable = (notif: CrewNotification) => {
		var canEdit = true;

		if (notif.schedule.lastSent !== null) {
			if (notif.schedule.recurrence === 'once') {
				canEdit = false;
			} else {
				if (moment(notif.schedule.endDate).isBefore(moment().utc())) {
					canEdit = false;
				}
			}
		}

		return canEdit;
	};

	createTableData = (): GridRowsProp => {
		var notifs = this.notificationRegistry;
		var crew = this.rootStore.dataStore.employeeStore.crewRegistry;
		var rows: GridRowsProp = [];
		notifs.forEach((not) => {
			let isGroup = not.recipientType === 'group';
			// let recurrence = not.schedule.recurrence === 'daily' ? 'YES' : 'NO';
			let multipleRecips = not.recipients.length > 1;
			// let canEdit = not.schedule.lastSent ? false : moment(not.schedule.nextOccurrence).isAfter(moment().utc());
			let canEdit = this.isNotificationEditable(not);
			let firstRecipient = not.recipients[0];
			let firstCrew = crew.has(firstRecipient) ? crew.get(firstRecipient) : null;

			rows.push({
				options: canEdit ? 'edit' : 'no',
				id: not.notificationId,
				notifId: not.notificationId,
				crewId: isGroup ? 'Group' : firstCrew?.crewId,
				empName: isGroup ? 'Group' : multipleRecips ? `${firstCrew?.shortName} + ${not.recipients.length - 1}` : firstCrew?.shortName,
				pos: isGroup ? not.recipients.join(', ') : firstCrew?.position,
				sender: not.sender,
				created: moment(not.createdDateId),
				// recurring: recurrence,
			});
			isGroup = false;
		});

		return rows;
	};
}
