import AmericanLight from '../fonts/AmericanLight.ttf';
import AmericanBook from '../fonts/AmericanBook.ttf';
import AmericanRegular from '../fonts/AmericanRegular.ttf';
import AmericanMedium from '../fonts/AmericanMedium.ttf';
import AmericanBold from '../fonts/AmericanBold.ttf';
// import AmericanBoldItalic from '../fonts/AmericanBoldItalic.ttf';
// import AmericanBookItalic from '../fonts/AmericanBookItalic.ttf';
// import AmericanLightItalic from '../fonts/AmericanLightItalic.ttf';
// import AmericanMediumItalic from '../fonts/AmericanMediumItalic.ttf';
// import AmericanRegularItalic from '../fonts/AmericanRegularItalic.ttf';

export const Light = {
	fontFamily: 'American',
	// fontWeight: 100,
	src: `url(${AmericanLight})`,
	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const Book = {
	fontFamily: 'American',
	// fontWeight: 400,
	src: `url(${AmericanBook})`,
	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const Regular = {
	fontFamily: 'American',
	fontWeight: 300,
	src: `url(${AmericanRegular})`,
	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const Medium = {
	fontFamily: 'American',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${AmericanMedium})`,
	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const Bold = {
	fontFamily: 'American',
	fontStyle: 'bold',
	fontDisplay: 'swap',
	fontWeight: 500,
	src: `url(${AmericanBold})`,
	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// export const LightItalic = {
// 	fontFamily: 'American',
// 	// fontStyle: 'italic',
// 	// fontDisplay: 'swap',
// 	// fontWeight: 100,
// 	src: `url(${AmericanLightItalic}) format('truetype')`,
// 	// src: `local('American'),
//     // local('American-LightItalic'),
// 	// url(${AmericanLightItalic}) format('truetype')`,
// 	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };
// export const BookItalic = {
// 		fontFamily: 'American',
// 		// fontStyle: 'bold',
// 		// fontDisplay: 'swap',
// 		// fontWeight: '300',
// 		src: `url(${AmericanBookItalic})`,
// 		unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };
// export const RegularItalic = {
	// 	fontFamily: 'American',
	// 	// fontStyle: 'italic',
	// 	// fontDisplay: 'swap',
// 	// fontWeight: 'bold',
// 	src: `url(${AmericanRegularItalic})`,
// 	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };
// export const MediumItalic = {
// 	fontFamily: 'American',
// 	// fontStyle: 'italic',
// 	// fontDisplay: 'swap',
// 	// fontWeight: '300',
// 	src: `url(${AmericanMediumItalic})`,
// 	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };
// export const BoldItalic = {
// 	fontFamily: 'American',
// 	// fontStyle: 'italic',
// 	// fontDisplay: 'swap',
// 	// fontWeight: '300',
// 	src: `url(${AmericanBoldItalic})`,
// 	unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };
