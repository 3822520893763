import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import { RegisteredCrew } from '../../../models/RegisteredCrew';
import { Chip } from '@material-ui/core';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

const CrewSelectionReview = () => {
	const {
		uiStore: { wizardStore },
	} = useStore();
	const { selectedRecipients } = wizardStore;

	const renderChips = (value: RegisteredCrew[]) => {
		return value.map((crewChip) => (
			<Chip key={crewChip.crewId} avatar={<Avatar>{crewChip.position}</Avatar>} disabled color='primary' variant='default' label={crewChip.chipName} size='small' />
		));
	};

	return (
		<Autocomplete
			multiple
			fullWidth
			disabled
			size='small'
			id='crew-input-review'
			value={selectedRecipients}
			options={selectedRecipients}
			renderTags={(value) => renderChips(value)}
			renderInput={(params) => <TextField variant='outlined' label='Recipient(s)' {...params} />}
		/>
	);
};

export default observer(CrewSelectionReview);
