import { RootStore } from '../RootStore';
import FilterStore from './FilterStore';
import WizardStore from './WizardStore';
import ReportStore from './ReportStore';

export default class UiStore {
	wizardStore: WizardStore;
	filterStore: FilterStore;
	reportStore: ReportStore;

	constructor(rootStore: RootStore) {
		this.wizardStore = new WizardStore(rootStore);
		this.filterStore = new FilterStore(rootStore);
		this.reportStore = new ReportStore(rootStore);
	}
}
