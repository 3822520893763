import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type AlertProps = {
	open: boolean;
	title: string;
	messageLines: string[];
	confirmHandler: () => void;
	confirmLabel: string;
	cancelHandler?: () => void;
	cancelLabel?: string;
	additionalContent?: React.ReactElement;
	success?: boolean;
};

const AlertDialog = (props: AlertProps) => {
	const { title, open, messageLines, confirmHandler, cancelHandler, confirmLabel, cancelLabel, additionalContent, success } = props;
	return (
		<div>
			<Dialog open={open}>
				<DialogTitle disableTypography style={{ alignSelf: 'center', fontSize: 30, marginBottom: 0 }}>
					{title}
				</DialogTitle>
				<DialogContent style={{ marginBottom: 0 } }>
					{messageLines.map((line) => {
						return (
							<DialogContentText style={{ textAlign: 'center', color: success ? 'darkgreen': 'darkred'  }} key={line}>
								{line}
							</DialogContentText>
						);
					})}
				</DialogContent>
				<DialogContent style={{alignSelf: 'center'}}>{additionalContent}</DialogContent>
				<DialogActions style={{alignSelf: 'center', margin: 10}}>
					{cancelLabel ? (
						<Button onClick={cancelHandler} color='secondary' autoFocus>
							{cancelLabel}
						</Button>
					) : null}
					<Button onClick={confirmHandler} variant='contained' color='primary'>
						{confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AlertDialog;
