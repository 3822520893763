import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditMessageOptions from '../edit/EditMessageOptions';
import { Grid, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/RootStore';
import { useAuthenticatedAxios } from '../../../auth/AuthenticatedAxios';
import AlertDialog from '../../../utils/Alert';
import { Messages } from '../../../utils/CommonConstants';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	error: {
		marginBottom: 10,
	},
	paper: {
		margin: 0,
		padding: theme.spacing(3),
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
}));

const EditNotificationWizard = () => {
	const { client } = useAuthenticatedAxios();
	const {
		uiStore: { wizardStore },
		dataStore: {
			crewNotificationStore: { deleteNotification, updateNotification, currentNotification },
		},
	} = useStore();
	const classes = useStyles();
	const {
		setEditWizardIsVisible,
		resetWizardContent,
		validateWizardContent,
		setMissingContentErrorVisible,
		setDeleteConfirmationVisible,
		setStatusVisible,
		isGroup,
		missingContentErrorVisible,
		deleteError,
		deleteConfirmationVisible,
		statusVisible,
		isCASelected,
		isFOSelected,
		isFASelected,
		selectedSendDateTime,
		nextOccurrence,
		selectedExpirationDate,
		title,
		content,
		selectedRecipients,
	} = wizardStore;

	const handleClose = () => {
		setEditWizardIsVisible(false);
		resetWizardContent();
		setStatusVisible(false);
		setDeleteConfirmationVisible(false);
	};
	const handleCloseError = () => {
		setMissingContentErrorVisible(false);
	};

	const handleDeleteConfirmation = () => {
		setDeleteConfirmationVisible(true);
	};
	const handleDelete = () => {
		deleteNotification(client);
		setDeleteConfirmationVisible(false);
	};

	const handleUpdate = () =>
	{
		if (validateWizardContent()) {
			var newRecips: string[] = [];
			
			if (isGroup) {
				if (isCASelected) newRecips.push('CA');
				if (isFOSelected) newRecips.push('FO');
				if (isFASelected) newRecips.push('FA');
			} else {
				selectedRecipients.forEach((recip) => {
					newRecips.push(recip.crewId);
				});
			}
			currentNotification!.pushData.data.title = title;
			currentNotification!.pushData.data.body = content;
			currentNotification!.schedule.endDate = nextOccurrence?.isAfter(selectedExpirationDate) ? nextOccurrence : selectedExpirationDate;
			currentNotification!.schedule.beginDate = nextOccurrence?.isBefore(selectedSendDateTime) ? nextOccurrence : selectedSendDateTime;
			currentNotification!.schedule.nextOccurrence = nextOccurrence;
			currentNotification!.recipients = newRecips;
			updateNotification(client);
		} else {
			setMissingContentErrorVisible(true);
		}
	};

	const MessageStatus = () => {
		return (
			<React.Fragment>
				<Grid container className={classes.root} spacing={2}>
					<Grid item xs={12}>
						<Grid container justify='center'>
							<Typography variant='h5' gutterBottom>
								{deleteError ? 'There was an error while attempting to delete the message' : 'The message was deleted'}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify='center'>
							<Button variant='text' color='primary' onClick={handleClose} className={classes.button}>
								Close
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Paper className={classes.paper} variant='elevation'>
				<Typography style={{ marginBottom: 30 }} component='h1' variant='h4' align='center'>
					Edit Message
				</Typography>
				{missingContentErrorVisible ? (
					<Alert className={classes.error} onClose={handleCloseError} severity='error'>
						{`Message must have Title, Content and ${isGroup ? 'Group' : 'Recipient(s)'}`}
					</Alert>
				) : null}
				<React.Fragment>
					{statusVisible ? (
						<MessageStatus />
					) : (
						<React.Fragment>
							<EditMessageOptions />
							<div className={classes.buttons}>
								<Button variant='text' color='secondary' onClick={handleClose} className={classes.button}>
									Cancel
								</Button>
								<Button variant='outlined' color='secondary' onClick={handleDeleteConfirmation} className={classes.button}>
									Delete Message
								</Button>
								<Button variant='contained' onClick={handleUpdate} className={classes.button}>
									Update Message
								</Button>
							</div>
						</React.Fragment>
					)}
				</React.Fragment>
			</Paper>
			<AlertDialog
				open={deleteConfirmationVisible}
				title={Messages.confirmDeleteTitle}
				messageLines={Messages.confirmDeleteMessageLines}
				confirmLabel='Delete'
				confirmHandler={handleDelete}
				cancelLabel='Cancel'
				cancelHandler={handleClose}
			/>
		</React.Fragment>
	);
};

export default observer(EditNotificationWizard);
