import { capitalize } from '@material-ui/core';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { AxiosInstance } from 'axios';
import { makeAutoObservable, runInAction, set } from 'mobx';
import { RegisteredCrew } from '../../models/RegisteredCrew';
import { RootStore } from '../RootStore';
import { Routes } from '../../utils/CommonConstants'

export default class EmployeeStore {
	rootStore: RootStore;
	crewRegistry = new Map<string, RegisteredCrew>();
	selectedCrew: RegisteredCrew[] = [];
	loading = false;
	loggedInUser: string = '';

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}

	loadRegisteredCrew = async (client: AxiosInstance) => {
		var response: RegisteredCrew[] = await(await client.get(`/${Routes.GetRegisteredCrewFromCosmos}`)).data;
		runInAction(() => {
			this.crewRegistry.clear();
			response.forEach((crew) =>
			{
				var crewId = crew['crewId'];
				var aaId = crew['aaId'];
				var pos = crew['position'];
				var fname = capitalize(crew['firstName'].toLowerCase());
				var lname = capitalize(crew['lastName'].toLowerCase());
				var regCrew = new RegisteredCrew(crewId, aaId, pos, fname, lname);
				set(this.crewRegistry, regCrew.crewId, regCrew);
			});
		});
	};

	setLoggedInUser = (user: string) => {
		this.loggedInUser = user;
	};

	updateSelectedRecipients = (e: React.ChangeEvent<{}>, v: RegisteredCrew[], r: AutocompleteChangeReason) => {
		v.forEach((crew) => {
			switch (r) {
				case 'select-option':
					crew.selected = true;
					break;
				case 'remove-option':
					crew.selected = false;
					break;
				case 'clear':
					this.crewRegistry.forEach((crew) => (crew.selected = false));
					break;
			}
			this.crewRegistry.set(crew.crewId, crew);
		});
	};
}
