import moment, { Moment } from 'moment';
import { Recurrence } from './CommonTypes';
import { makeAutoObservable } from 'mobx';

class Schedule {
	beginDate: Moment;
	nextOccurrence: Moment | null;
	lastSent: Moment | null;
	endDate: Moment;
	recurrence: Recurrence;

	constructor(beginDate: Moment = moment(), endDate: Moment = moment(), lastSent: Moment | null = null, nextOccurrence: Moment | null = null, recurrence: Recurrence = 'once') {
		makeAutoObservable(this);
		this.beginDate = beginDate;
		this.nextOccurrence = nextOccurrence;
		this.lastSent = lastSent;
		this.endDate = endDate;
		this.recurrence = recurrence;
	}
}

export default Schedule;
