import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import CrewNeutralSelectPanel from '../components/custom/filterPanel/CrewNeutralSelectPanel';
import AlertDialog from '../utils/Alert'
import { Links, Messages } from '../utils/CommonConstants'
import { useStore } from '../stores/RootStore'

const CrewNeutralReport = () =>
{
	const {
		dataStore: {
			reportDataStore: { reportGenerationAlertVisible, reportGenerationWasSuccessful, setReportGenerationAlertVisible },
		},
	} = useStore();

	return (
		<React.Fragment>
			<Grid container direction='column'>
				<CrewNeutralSelectPanel />
			</Grid>
			<AlertDialog
				open={reportGenerationAlertVisible}
				title={Messages.reportGenerationMessageTitle}
				messageLines={reportGenerationWasSuccessful ? Messages.reportGenerationSuccessMessageLines : Messages.reportGenerationFailureMessageLines}
				confirmHandler={() => {
					setReportGenerationAlertVisible(false);
				}}
				confirmLabel='CLOSE'
				success={ reportGenerationWasSuccessful }
				additionalContent={
					<React.Fragment>
						{reportGenerationWasSuccessful ? (
							<a style={{ alignItems: 'center' }} href={Links.CrewNeutralTableauReportLink} target='_blank' rel='noreferrer'>
								<Typography variant='h6'>Crew Neutral Report</Typography>
							</a>
						) : null}
					</React.Fragment>
				}
			/>
		</React.Fragment>
	);
};

export default observer(CrewNeutralReport);
