import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { Avatar, Chip } from '@material-ui/core';
import { RegisteredCrew } from '../../../models/RegisteredCrew';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

const CrewSelector = () => {
	const {
		uiStore: { wizardStore },
		dataStore: { employeeStore },
	} = useStore();
	const { updateSelectedRecipients, selectedRecipients } = wizardStore;
	const { crewRegistry } = employeeStore;

	const renderChips = (value: RegisteredCrew[], getTagProps: AutocompleteGetTagProps) => {
		return value.map((crewChip, index) => (
			<Chip
				key={crewChip.crewId}
				avatar={<Avatar>{crewChip.position}</Avatar>}
				color='primary'
				variant='default'
				label={crewChip.chipName}
				size='small'
				{...getTagProps({ index })}
			/>
		));
	};

	return (
		<Autocomplete
			multiple
			size='small'
			id='crew-input'
			onChange={(e, v, r) => updateSelectedRecipients(e, v, r)}
			value={selectedRecipients}
			filterSelectedOptions
			options={[...crewRegistry.values()]}
			getOptionLabel={(crew) => crew.optionDisplayName}
			limitTags={4}
			style={{ width: '100%' }}
			renderTags={(value, getTagProps) => renderChips(value, getTagProps)}
			renderInput={(params) => <TextField multiline rowsMax={5} variant='outlined' label='Recipient(s)' {...params} />}
		/>
	);
};

export default observer(CrewSelector);
