import React from 'react';

import MainContent from './MainContent';
import SideDrawer from './SideDrawer';
import Header from './Header';
import { Container } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(0),
			padding: theme.spacing(0),
			display: 'flex',
		},
	})
);

const MainLayout: React.FC = () => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Container maxWidth='xl' className={classes.root}>
				<Header />
				<SideDrawer />
				<MainContent />
			</Container>
		</React.Fragment>
	);
}

export default MainLayout