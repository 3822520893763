import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CrewSelector from '../../custom/crewSelection/CrewSelector';
import CrewGroupSelector from '../../custom/crewSelection/CrewGroupSelector';
import { useStore } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
// These two imports are for the "Request Response" feature that is commented out below
// This will be implemented in production in the future.  DO NOT REMOVE
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

const MessageContent = () => {
	const {
		uiStore: { wizardStore },
	} = useStore();
	const { title, content, isGroup, setTitle, setContent } = wizardStore;
	// These will replace the two above when the "Response" feature is added
	// const { title, content, responseRequested, isGroup } = wizardStore;
	// const { setTitle, setContent, setResponseRequested } = wizardStore;

	const handleValidateInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		//Limit the line breaks to 5
		if (e.target.value.split('\n').length > 5) {
			setContent(e.target.value.trimEnd());
		} else {
			setContent(e.target.value);
		}
	};
	return (
		<React.Fragment>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					{isGroup ? <CrewGroupSelector /> : <CrewSelector />}
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						inputProps={{ maxLength: 30 }}
						id='notificationTitle'
						onChange={(e) => setTitle(e.target.value)}
						value={title}
						label='Title'
						fullWidth
						variant='outlined'
						size='small'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						inputProps={{ maxLength: 150 }}
						id='notificationContent'
						onChange={(e) => handleValidateInput(e)}
						value={content}
						label='Message Content'
						fullWidth
						variant='outlined'
						multiline
						rows={10}
					/>
					<Typography style={{ color: 'gray' }} align='right'>{`${content.length}/150`}</Typography>
				</Grid>

				{/* This option won't be in the first release but we plan to provide it in the future.  Don't remove this section
				{isGroup ? null : (
					<Grid item xs={12}>
						<FormControlLabel
							control={<Checkbox checked={responseRequested} color='primary' name='requestResponse' onChange={() => setResponseRequested(!responseRequested)} />}
							label='Request Response'
						/>
					</Grid>
				)} */}
			</Grid>
		</React.Fragment>
	);
};

export default observer(MessageContent);
