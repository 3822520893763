import { Configuration } from '@azure/msal-browser';

let authority = process.env.REACT_APP_AUTHORITY;
let apiVersion = process.env.REACT_APP_API_VERSION;

let redirectUri = process.env.REACT_APP_REDIRECT_URL;
let baseURL = process.env.REACT_APP_BASE_URL;
let clientId = process.env.REACT_APP_CLIENT_ID;

// console.log(authority);
// console.log(apiVersion);
// console.log(redirectUri);
// console.log(baseURL);
// console.log(clientId);
// console.log(process.env.REACT_APP_ENV);

export const MSAL_CONFIG: Configuration = {
	auth: {
		clientId: clientId!,
		authority: authority,
		redirectUri: redirectUri,
		postLogoutRedirectUri: `${redirectUri}/signedOutPage`,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
	},
};

export const apiConfig = {
	notificationManagerUrl: baseURL!+apiVersion,
	resourceScopes: [`${baseURL}/user_impersonation`],
};

export const loginRequest = {
	scopes: ['User.Read', ...apiConfig.resourceScopes],
};

export const silentRequest = {
	scopes: [...apiConfig.resourceScopes],
};
