import React, { useEffect } from 'react';
import FilterPanel from '../components/custom/filterPanel/FilterPanel';
import { Button, Dialog, DialogContent, FormControl, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Refresh } from '@material-ui/icons';
import NotificationWizard from '../components/notificationWizard/create/NotificationWizard';
import NotificationsTable from '../components/custom/table/NotificationsTable';
import { useStore } from '../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { useAuthenticatedAxios } from '../auth/AuthenticatedAxios';
import EditNotificationWizard from '../components/notificationWizard/edit/EditNotificationWizard';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1, 1),
		},
		dialog: {
			margin: 0,
			padding: 0,
		},
	})
);
const CustomNotificationDashboard = () => {
	const classes = useStyles();
	const {
		uiStore: { wizardStore },
		dataStore: {
			crewNotificationStore: { loadNotificationsFromCosmos },
			employeeStore: { loadRegisteredCrew },
		},
	} = useStore();
	const { setCreateWizardIsVisible, resetWizardContent } = wizardStore;
	const { createWizardIsVisible, editWizardIsVisible } = wizardStore;
	const { client } = useAuthenticatedAxios();

	useEffect(() => {
		setTimeout(() => {
			fetchData();
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		loadRegisteredCrew(client)
			.then((response) => {
				loadNotificationsFromCosmos(client)
					.then(() => {})
					.catch((error) => {
						console.log(`Error in loadNotifs: ${error}`);
					});
			})
			.catch((error) => {
				console.log(`Error on loadCrew: ${error}`);
			});
	};

	const handleNewMessageClick = () => {
		resetWizardContent();
		setCreateWizardIsVisible(true);
	};
	
	return (
		<React.Fragment>
			<Grid container direction='column'>
				<Grid item>
					<FilterPanel />
				</Grid>
				<Grid item container>
					<FormControl className={classes.formControl}>
						<Button color='primary' onClick={() => handleNewMessageClick()} startIcon={<Add />} variant='contained'>
							New Message
						</Button>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Button color='primary' onClick={fetchData} startIcon={<Refresh />} variant='contained'>
							Refresh
						</Button>
					</FormControl>
				</Grid>
				<Grid item>
					<NotificationsTable />
				</Grid>
			</Grid>
			<Dialog className={classes.dialog} fullWidth open={createWizardIsVisible}>
				<DialogContent className={classes.dialog}>
					<NotificationWizard />
				</DialogContent>
			</Dialog>
			<Dialog className={classes.dialog} fullWidth open={editWizardIsVisible}>
				<DialogContent className={classes.dialog}>
					<EditNotificationWizard />
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

export default observer(CustomNotificationDashboard);
