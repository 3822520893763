import React from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../auth/AuthConfig'
import Loading from './Loading'

const Home: React.FC = () => {
	return (
		<React.Fragment>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} loadingComponent={Loading} />
			<h1>Welcome to the PSA Crew Suite</h1>
			<h1>Please select an application on the left to get started.</h1>
		</React.Fragment>
	);
}
export default Home