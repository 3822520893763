import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/RootStore';

const CrewGroupSelector = () => {
	const {
		uiStore: { wizardStore },
	} = useStore();
	const { isCASelected, isFOSelected, isFASelected, setIsCASelected, setIsFOSelected, setIsFASelected } = wizardStore;

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Grid item container alignItems='center' spacing={0}>
					<Grid item>
						<Typography>Send Message To All*:&nbsp;&nbsp;</Typography>
					</Grid>
					<Grid item>
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={isCASelected} color='primary' name='CA' onChange={(event) => setIsCASelected(event.target.checked)} />}
								label='Captains'
							/>
							<FormControlLabel
								control={<Checkbox checked={isFOSelected} color='primary' name='FO' onChange={(event) => setIsFOSelected(event.target.checked)} />}
								label='First Officers'
							/>
							<FormControlLabel
								control={<Checkbox checked={isFASelected} color='primary' name='FA' onChange={(event) => setIsFASelected(event.target.checked)} />}
								label='Flight Attendants'
							/>
						</FormGroup>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default observer(CrewGroupSelector);
