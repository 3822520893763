import { NotificationEvent } from './CommonTypes';
import { v4 as uuidv4 } from 'uuid';

class Data {
	title: string;
	body: string;
	event: NotificationEvent = 'RELOAD';
	msgId: string = uuidv4();
	select: string;

	constructor(title: string, body: string) {
		this.title = title;
		this.body = body;
		this.select = "OK";
	}
}
export class PushMessage {
	data: Data;
	constructor(title: string, body: string) {
		this.data = new Data(title, body);
	}
}

export default PushMessage;
